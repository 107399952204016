{
  "name": "@twilio/webrtc",
  "version": "4.3.2",
  "description": "WebRTC-related APIs and shims used by twilio-video.js",
  "scripts": {
    "build": "npm-run-all clean lint test",
    "clean": "rimraf coverage",
    "lint": "eslint ./lib",
    "test:unit": "istanbul cover node_modules/mocha/bin/_mocha -- ./test/unit/index.js",
    "test:integration:native": "karma start karma/integration.conf.js",
    "test:integration:adapter": "karma start karma/integration.adapter.conf.js",
    "test:integration": "npm-run-all test:integration:*",
    "test": "npm-run-all test:*"
  },
  "repository": {
    "type": "git",
    "url": "git+https://github.com/twilio/twilio-webrtc.js.git"
  },
  "keywords": [
    "shim",
    "twilio",
    "video",
    "webrtc"
  ],
  "author": "Manjesh Malavalli <mmalavalli@twilio.com>",
  "contributors": [
    "Mark Roberts <mroberts@twilio.com>",
    "Ryan Rowland <rrowland@twilio.com>",
    "Makarand Patwardhan <mpatwardhan@twilio.com>"
  ],
  "license": "BSD-3-Clause",
  "main": "./lib/index.js",
  "bugs": {
    "url": "https://github.com/twilio/twilio-webrtc.js/issues"
  },
  "homepage": "https://github.com/twilio/twilio-webrtc.js#readme",
  "devDependencies": {
    "browserify": "^14.4.0",
    "electron": "^5.0.0",
    "envify": "^4.1.0",
    "eslint": "^4.4.1",
    "is-docker": "^2.0.0",
    "istanbul": "^0.4.5",
    "karma": "^1.7.0",
    "karma-browserify": "^5.1.1",
    "karma-chrome-launcher": "^2.2.0",
    "karma-edgium-launcher": "^4.0.0-0",
    "karma-electron": "^6.1.0",
    "karma-firefox-launcher": "^1.0.1",
    "karma-htmlfile-reporter": "^0.3.8",
    "karma-junit-reporter": "^1.2.0",
    "karma-mocha": "^1.3.0",
    "karma-safari-launcher": "~0.1",
    "karma-spec-reporter": "0.0.31",
    "mocha": "^3.5.0",
    "npm-run-all": "^4.0.2",
    "twilio-release-tool": "^1.0.0",
    "rimraf": "^2.6.1",
    "simple-git": "^2.4.0",
    "watchify": "^3.9.0",
    "webrtc-adapter": "^6.4.8"
  }
}
